import mixins from 'vue-typed-mixins';
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

import StudyResultDialog from '@/user/components/study/dialog/result/StudyResultDialog.vue';

import RoutesMixin from '@/user/mixins/RoutesMixin';
import StudyMixin from '@/user/mixins/StudyMixin';
import WindowAdjustMixin from '@/user/mixins/WindowAdjustMixin';
import getQuestion from '@/master/janken';

/** じゃんけんの手 */
enum Hand {
  G = 'G', // グー
  C = 'C', // チョキ
  P = 'P', // パー
};

/** 勝負結果 */
enum Showdown {
  W = 'W', // 勝ち
  D = 'D', // 引き分け
  L = 'L', // 負け
};

/** 画像サイズ種別 */
enum ImageSizeType {
  L = 1,
  M = 2,
  S = 3,
}

/** 回答欄情報 */
type ReplyBox = {
  hand: Hand | null;
  coordinate: ReplyBoxCoordinate | null;
  showdown: Showdown | null;
};

/** 回答欄座標情報 */
type ReplyBoxCoordinate = {
  x: number;
  y: number;
};

/** ドラッグアイテム */
type DragItem = {
  hand: Hand;
  initialX: number;
  initialY: number;
  x: number;
  y: number;
  isDragging: boolean;
};

/** 勝敗ラベル設定 */
type ShowdownLabel = {
  regular: string;
  short: string;
};

/** 入力補助 */
type Assistance = {
  hand: Hand;
  coordinate: ReplyBoxCoordinate | null;
};

/** 勝敗ラベル */
const SHOWDOWN_LABEL: { [key in Showdown]: ShowdownLabel } = {
  W: {
    regular: '勝ち',
    short: '勝',
  },
  D: {
    regular: '引き分け',
    short: '分',
  },
  L: {
    regular: '負け',
    short: '負',
  },
};

/** チュートリアルの回答情報 */
const TUTORIAL_REPLIES = [
  {
    sectionNo: 1,
    unitNo: 1,
    hands: [
      [Hand.P],
    ]
  },
  {
    sectionNo: 1,
    unitNo: 4,
    hands: [
      [Hand.P, Hand.C],
    ]
  },
  {
    sectionNo: 3,
    unitNo: 1,
    hands: [
      [Hand.G],
      [Hand.C],
    ]
  },
  {
    sectionNo: 3,
    unitNo: 4,
    hands: [
      [Hand.G, Hand.G],
      [Hand.C, Hand.C],
    ]
  },
  {
    sectionNo: 3,
    unitNo: 5,
    hands: [
      [Hand.G, Hand.G],
      [Hand.P, Hand.G],
      [],
    ]
  },
] as const;

export default mixins(RoutesMixin, StudyMixin, WindowAdjustMixin).extend({
  name: 'Janken',

  components: {
    VueDraggableResizable,
    StudyResultDialog,
  },

  data(): {
    question: {
      hands: Hand[];
      showdowns: Showdown[];
      lines: number;
    };
    reply: {
      boxes: ReplyBox[][],
      currentLine: number,
    },
    drag: {
      readonly gcpCount: number;
      items: DragItem[]
    },
  } {
    return {
      question: {
        hands: [],
        showdowns: [],
        lines: 1 // 何回戦
      },
      reply: {
        boxes: [],
        currentLine: 1,
      },
      drag: {
        gcpCount: 2, // 操作用の手＋ダミー用の手
        items: [],
      },
    };
  },

  computed: {
    isBlindQuestion(): boolean {
      return this.sectionNo > 2;
    },
    replyBoxCount(): {
      column: number;
      line: number;
    } {
      const arr = this.isBlindQuestion ? this.question.showdowns : this.question.hands;
      return {
        column: arr.length,
        line: this.question.lines
      };
    },
    currentReplyLineBoxes(): ReplyBox[] {
      return this.reply.boxes[this.reply.currentLine - 1];
    },
    imageSizeType(): ImageSizeType {
      switch (this.replyBoxCount.line) {
        case 1:
          return ImageSizeType.L;
        case 2:
          return ImageSizeType.M;
        default:
          return ImageSizeType.S;
      }
    },
    handImageSize(): number {
      switch (this.imageSizeType) {
        case ImageSizeType.L:
          return this.calculateRatio(80);
        case ImageSizeType.M:
          return this.calculateRatio(60);
        case ImageSizeType.S:
          return this.calculateRatio(40);
      }
    },
    questionImgSrcs(): string[] {
      const srcs: string[] = [];
      for (let i = 0; i < this.replyBoxCount.column; i++) {
        const hand = this.isBlindQuestion ? 'blind' : this.question.hands[i];
        srcs.push(require(`@/assets/img/study/janken/janken_question_${hand}.svg`));
      }
      return srcs;
    },
    handBoxImgSrc(): string {
      return require(`@/assets/img/study/janken/janken_area${this.imageSizeType}.svg`);
    },
    assistance(): Assistance | null {
      const data = TUTORIAL_REPLIES.find(data => data.sectionNo === this.sectionNo && data.unitNo === this.unitNo);
      if (data === undefined) return null;

      // 回答欄の回答とチュートリアルの手を比較
      for (let i = 0; i < this.reply.boxes.length; i++) {
        const replyLineBoxes = this.reply.boxes[i];
        for (let j = 0; j < replyLineBoxes.length; j++) {
          // 回答とチュートリアルの手が一致しない場合は入力補助情報を返却
          const replyBox = replyLineBoxes[j];
          const hand     = data.hands[i][j];
          if (replyBox.hand !== hand) return {
            hand,
            coordinate: replyBox.coordinate
          };
        }
      }
      return null;
    },
    assistanceStyle(): Record<string, string> {
      if (this.assistance === null) return {};
      const coordinate = this.assistance.coordinate;
      const dragItem   = this.drag.items.find(item => item.hand === this.assistance!.hand);
      if (coordinate === null || dragItem === undefined) return {};

      const srcX  = dragItem.initialX + (this.handImageSize / 2);
      const srcY  = dragItem.initialY + (this.handImageSize / 2);
      const dstX  = coordinate.x + (this.handImageSize / 2) + this.calculateRatio(5);
      const dstY  = coordinate.y + (this.handImageSize / 2) + this.calculateRatio(5);
      const width = Math.sqrt(Math.pow(srcX - dstX, 2) + Math.pow(srcY - dstY, 2));
      const deg   = Math.atan2(dstY - srcY, dstX - srcX) / (Math.PI / 180);
      return {
        '-webkit-transform': `rotate(${deg}deg)`,
        transform: `rotate(${deg}deg)`,
        top: `${srcY}px`,
        left: `${srcX}px`,
        width: `${width}px`,
      };
    }
  },

  created(): void {
    this.initializeStudyVariable();
    this.initializeStudyRequest();
    this.$_initializeQuestion();
  },

  mounted(): void {
    this.onChangeWindowSizeHandler();
  },

  filters: {
    showdownLabel(replyLine: ReplyBox[]): string {
      // 集計用オブジェクトを初期化
      const showdownCount: { [key in Showdown]: number } = {
        W: 0,
        D: 0,
        L: 0,
      };

      // 勝敗集計
      replyLine.forEach(cell => {
        if (cell.showdown !== null) showdownCount[cell.showdown] ++;
      });

      // 1列の場合「勝ち・負け・引き分け」表記
      // 複数列の場合「XX勝XX分XX負」表示。ただし0の項目は出さない
      let label = '';
      Object.entries(SHOWDOWN_LABEL).forEach(([key, config]) =>{
        const count = showdownCount[key as Showdown];
        if (count > 0) {
          label += replyLine.length === 1 ? config.regular : count + config.short;
        }
      });
      return label;
    },
  },

  methods: {
    getReplyImgSrc(hand: Hand | null): string {
      return require(`@/assets/img/study/janken/janken_choice_${hand ?? 'empty'}.svg`);
    },
    onDragging(index: number): void {
      if (this.drag.items[index].isDragging) return;
      this.drag.items[index].isDragging = true;
    },
    async onDragstop(left: number, top: number, index: number): Promise<void> {
      // 操作中の手
      const dragItem = this.drag.items[index];

      // ドラッグ状態を戻す
      dragItem.isDragging = false;

      // xyの値が変更されないと位置更新できないので
      // 一度ドラッグ位置に配置して明示的に更新をかける
      dragItem.x = left;
      dragItem.y = top;
      await this.$nextTick();

      // 格納位置チェック
      const x = left + this.handImageSize / 2;
      const y = top  + this.handImageSize / 2;
      this.currentReplyLineBoxes.forEach(cell => {
        const rect = cell.coordinate;
        if (rect === null) return;
        // 位置が一致する場合、かつ異なる手を入れた場合
        const right  = rect.x + this.handImageSize;
        const bottom = rect.y + this.handImageSize;
        const isHit  = rect.x < x && x < right && rect.y < y && y < bottom;
        if (isHit && cell.hand !== dragItem.hand) {
          // チュートリアル、相手の手が見えない問題、1回戦目の場合はチュートリアルに設定されている手以外は格納不可
          if (this.assistance !== null && this.isBlindQuestion && this.reply.currentLine === 1) {
            if (this.assistance.hand !== dragItem.hand) return;
          }

          // 回答格納
          cell.hand = dragItem.hand;
          // 正解生成（手が見えない問題、かつ1行目が埋まった時）
          this.$_decideAnswer();
          // 正誤判定
          this.$_judge();
        }
      });

      // 操作した手は元の位置に戻しておく
      dragItem.x = dragItem.initialX;
      dragItem.y = dragItem.initialY;
    },
    onCloseStudyResultDialog(): void {
      // 回答欄初期化
      this.$_initializeReply();
      this.$_calcHTMLSizePosition();
    },
    // WindowAdjustMixin上書き
    onChangeWindowSizeHandler(): void {
      this.$_calcHTMLSizePosition();
      // vue-draggable-resizableのparentサイズが変わらないので明示的にイベント通知
      window.dispatchEvent(new Event('resize'));
    },
    $_initializeQuestion(): void {
      // 問題設定
      const question = getQuestion(this.sectionNo, this.unitNo);
      const qs = question.question.split('');
      this.question = {
        hands: [],
        showdowns: [],
        lines: question.lines
      };
      if (this.isBlindQuestion) this.question.showdowns = qs.map(q => Showdown[q as keyof typeof Showdown]);
      else                      this.question.hands = qs.map(q => Hand[q as keyof typeof Hand]);

      // 回答欄初期化
      this.$_initializeReply();

      // じゃんけんの各手
      this.drag.items = [];
      Object.values(Hand).forEach(h => {
        for (let i = 0; i < this.drag.gcpCount; i++) {
          this.drag.items.push({
            hand: h,
            initialX: 0,
            initialY: 0,
            x: 0,
            y: 0,
            isDragging: false,
          });
        }
      });
    },
    $_initializeReply(): void {
      this.reply = {
        boxes: [],
        currentLine: 1,
      };
      for (let i = 0; i < this.replyBoxCount.line; i++) {
        const line: ReplyBox[] = [];
        for (let j = 0; j < this.replyBoxCount.column; j++) {
          line.push({
            hand: null,
            coordinate: null,
            showdown: null
          });
        }
        this.reply.boxes.push(line);
      }
    },
    $_decideAnswer(): void {
      // 問題の手が見える問題、または1行目の入力でない場合はreturn
      if (!this.isBlindQuestion || this.reply.currentLine !== 1) return;
      // 1行目に未入力のセルがある場合はreturn
      const hands = this.currentReplyLineBoxes.map(cell => cell.hand);
      if (hands.some(h => h === null)) return;

      // 回答返却関数
      const getAnswer = (showdown: Showdown, hand: Hand): Hand => {
        switch (showdown) {
          case Showdown.W:
            if (hand === Hand.G) return Hand.C;
            if (hand === Hand.C) return Hand.P;
            if (hand === Hand.P) return Hand.G;
            // falls through
          case Showdown.D:
            return hand;
            // falls through
          case Showdown.L:
            if (hand === Hand.G) return Hand.P;
            if (hand === Hand.C) return Hand.G;
            if (hand === Hand.P) return Hand.C;
            // falls through
          default:
            throw new Error(`Illegal answer: ${showdown}`);
        }
      };

      // 回答生成
      this.question.hands = [];
      this.question.showdowns.forEach((showdown, i) => {
        const answer = getAnswer(showdown, hands[i]!);
        this.question.hands.push(answer);
      });
    },
    async $_judge(): Promise<void> {
      // 回答中の行に未入力のセルがある場合は判定しない
      if (this.currentReplyLineBoxes.some(cell => cell.hand === null)) return;

      // 勝敗判定
      this.currentReplyLineBoxes.forEach((replyBox, i) => {
        const reply = replyBox.hand!;
        const question = this.question.hands[i];
        if (reply === question) {
          replyBox.showdown = Showdown.D;
        } else {
          switch (reply) {
            case Hand.G:
              if (question === Hand.C) replyBox.showdown = Showdown.W;
              if (question === Hand.P) replyBox.showdown = Showdown.L;
              break;
            case Hand.C:
              if (question === Hand.P) replyBox.showdown = Showdown.W;
              if (question === Hand.G) replyBox.showdown = Showdown.L;
              break;
            case Hand.P:
              if (question === Hand.G) replyBox.showdown = Showdown.W;
              if (question === Hand.C) replyBox.showdown = Showdown.L;
              break;
          }
        }
      });

      // 正誤判定（行の勝敗が全て勝ちの場合は正解）
      const isCorrect  = this.currentReplyLineBoxes.every(cell => cell.showdown === Showdown.W);
      const isLastLine = this.reply.currentLine === this.question.lines;

      // 正解、または最終行まで回答している場合は正誤判定結果を表示
      if (isCorrect || isLastLine) {
        this.judgeScoreResult(isCorrect);
        return;
      }

      // 次行を表示して再描画（次行表示による位置変更待ち）と回答欄・ドラッグ要素の位置調整
      this.reply.currentLine++;
      this.$_calcHTMLSizePosition();
    },
    async $_calcHTMLSizePosition(): Promise<void> {
      // DOM変更後に実行することがあるので最初に再描画しておく
      await this.$nextTick();
      // 回答欄とドラッグ要素の位置とサイズを再計算
      this.$_calcReplyBoxRects();
      this.$_calcItemSizePosition();
    },
    $_calcReplyBoxRects(): void {
      const elements = this.$refs.replyBoxes as HTMLElement[];
      if (elements === undefined || elements.length === 0) return; // 回答欄が存在しない場合はreturn

      let index = 0;
      this.reply.boxes.forEach(line => {
        line.forEach(cell => {
          const elm = elements[index];
          if (elm === undefined) return;

          const parent = elm.offsetParent as HTMLElement;
          cell.coordinate = {
            x: parent.offsetLeft + elm.offsetLeft,
            y: parent.offsetTop + elm.offsetTop,
          };
          index++;
        });
      });
    },
    async $_calcItemSizePosition(): Promise<void> {
      const handCount = Object.values(Hand).length;
      const itemWH    = this.handImageSize;
      const boxElm    = this.$refs.handBox as HTMLElement;
      const marginX   = (boxElm.offsetWidth - itemWH * handCount) / 4;
      const marginY   = (boxElm.offsetHeight - itemWH) / 2;
      let   x         = boxElm.offsetLeft + marginX;
      const y         = boxElm.offsetTop + marginY;
      Object.values(Hand).forEach(h => {
        const items = this.drag.items.filter(item => item.hand === h);
        items.forEach(item => {
          item.initialX = x;
          item.initialY = y;
          item.x        = x;
          item.y        = y;
        });
        x += itemWH + marginX;
      });
    },
  },
});
